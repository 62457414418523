<template>
	<svg viewBox="0 0 129 35" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M23.08 34.0746H18.9324L17.6612 27.8165C17.6132 27.5967 17.4908 27.3998 17.3143 27.2587C17.1379 27.1175 16.9182 27.0407 16.6916 27.0409L14.537 27.0784L14.1977 26.9339L8.93513 21.4621C8.79291 21.3016 8.67497 21.1214 8.585 20.9272L4.92221 12.2942C4.77108 11.9872 4.5606 11.7128 4.30277 11.4865L1.01703 8.6195L0.973942 8.43764L1.80884 4.76833L13.5244 12.2567L10.8042 16.6214L10.8689 16.8514L11.3644 17.1616L11.6176 17.1188L14.467 12.5669H17.6666C17.7241 12.5676 17.7807 12.5525 17.8301 12.5232C17.8796 12.4939 17.9199 12.4517 17.9467 12.4011L18.2645 11.8983C18.3291 11.7914 18.3453 11.6202 18.2645 11.6202H14.5855C14.4117 11.6085 14.2426 11.5592 14.09 11.4758L2.09971 3.80554C1.99778 3.73564 1.88008 3.69176 1.75701 3.67776C1.63394 3.66377 1.50929 3.6801 1.39409 3.7253C1.28232 3.77306 1.18353 3.84643 1.10582 3.9394C1.02811 4.03237 0.97369 4.1423 0.94701 4.26019L0.0528578 8.21299C0.01225 8.41733 0.024555 8.62857 0.088619 8.8269C0.152683 9.02524 0.266408 9.20417 0.419137 9.34694L3.70488 12.1818C3.84579 12.317 3.96078 12.4765 4.04422 12.6525L7.7124 21.2856C7.84776 21.583 8.02954 21.8574 8.25104 22.0986L13.5082 27.5758C13.7866 27.8464 14.1584 28.0013 14.5478 28.0091L16.7024 27.9716L17.9359 34.0426H14.9141C14.89 34.0439 14.8665 34.05 14.8448 34.0604C14.8231 34.0708 14.8037 34.0854 14.7876 34.1033C14.7716 34.1212 14.7593 34.142 14.7515 34.1647C14.7436 34.1873 14.7403 34.2112 14.7417 34.2351V34.8235L14.8979 35H23.0853L23.2416 34.8235V34.2351C23.2369 34.1941 23.2184 34.1559 23.1891 34.1268C23.1597 34.0976 23.1212 34.0792 23.08 34.0746Z"
			fill="#FFD923"
		/>
		<path
			d="M33.2119 2.85344C33.1777 2.71823 33.0959 2.59964 32.9813 2.51918C32.8667 2.43871 32.7268 2.4017 32.5871 2.41484L30.7826 2.33995L30.7449 2.31321L30.5994 1.48414C30.5629 1.26638 30.4594 1.06518 30.3032 0.908132C30.147 0.751081 29.9457 0.645869 29.7268 0.606926L25.8001 0.0185533C25.6684 -0.0140204 25.5296 -0.00350408 25.4044 0.0485378C25.2793 0.10058 25.1743 0.191366 25.1053 0.307391C25.0437 0.427925 25.022 0.564692 25.0432 0.698196C25.0645 0.8317 25.1277 0.955126 25.2238 1.05088L25.9456 1.9067V2.00832L14.2462 20.74L14.3108 20.9646L14.8064 21.2749L15.0595 21.2321L26.7589 2.51111C26.8717 2.32344 26.9239 2.10595 26.9085 1.88792C26.8931 1.66988 26.8108 1.46177 26.6727 1.29158L26.5004 1.08832L29.5814 1.54832L29.6622 1.62321L29.8076 2.47367C29.8482 2.69257 29.9616 2.89163 30.1295 3.03903C30.2975 3.18642 30.5104 3.27362 30.7341 3.2867H31.1435L30.3894 3.67181C30.2054 3.7797 30.0567 3.93804 29.9612 4.12781C29.8656 4.31757 29.8273 4.5307 29.8507 4.74158L30.077 6.43717L29.2636 6.08414C29.1457 6.02 29.0106 5.99427 28.8772 6.01059C28.7438 6.02691 28.6189 6.08445 28.5203 6.17507C28.4284 6.27368 28.3697 6.39824 28.3524 6.53146C28.335 6.66468 28.3598 6.79997 28.4233 6.91856L29.0158 8.31461L29.3444 9.0688L30.6425 12.0053V12.2781L20.4244 28.5707L20.209 28.6884H19.3202C19.1855 28.6884 19.1694 28.8007 19.1909 28.897L19.2987 29.4318C19.2987 29.5388 19.3741 29.6458 19.5195 29.6405H20.2036C20.4084 29.6353 20.6089 29.581 20.788 29.4823C20.9672 29.3836 21.1196 29.2433 21.2324 29.0735L31.402 12.813C31.504 12.6377 31.5647 12.4417 31.5797 12.2398C31.5946 12.0378 31.5633 11.8352 31.4882 11.6469L30.1955 8.71043L29.8723 7.96694L29.5868 7.29833L30.077 7.51228C30.1911 7.56932 30.3188 7.59401 30.4462 7.58363C30.5735 7.57325 30.6955 7.5282 30.7987 7.45345C30.8998 7.37409 30.9772 7.26898 31.0227 7.14933C31.0683 7.02969 31.0802 6.90001 31.0573 6.77414L30.7718 4.63461L30.8257 4.52763L32.851 3.5167C32.9798 3.46559 33.0865 3.37121 33.1525 3.25001C33.2184 3.1288 33.2394 2.98846 33.2119 2.85344Z"
			fill="#FFD923"
		/>
		<path
			d="M56.0128 25.8214C52.5223 24.7142 54.5045 20.6651 50.0607 17.8997L55.0216 10.4114C55.0762 10.3413 55.1102 10.2577 55.12 10.1697C55.1298 10.0816 55.1149 9.99265 55.0771 9.91247C55.0392 9.8323 54.9798 9.76405 54.9054 9.71523C54.831 9.66641 54.7445 9.63892 54.6554 9.63578H52.5008L52.1345 9.85508L46.5757 18.2314V6.0681C46.5856 6.0065 46.5829 5.94357 46.5678 5.88302C46.5527 5.82248 46.5255 5.76557 46.4878 5.71567C46.4501 5.66578 46.4027 5.62391 46.3484 5.59257C46.2941 5.56122 46.234 5.54104 46.1717 5.53321H44.2972C44.2349 5.54104 44.1748 5.56122 44.1205 5.59257C44.0662 5.62391 44.0188 5.66578 43.9811 5.71567C43.9434 5.76557 43.9162 5.82248 43.9011 5.88302C43.886 5.94357 43.8833 6.0065 43.8932 6.0681V27.2174L44.2972 27.6988H46.1717L46.6134 27.2174L46.5757 22.5746L48.6333 19.6702C51.7575 21.5102 50.5778 27.0035 55.0647 28.1428L55.6034 27.886L56.2282 26.49C56.2795 26.3715 56.286 26.2385 56.2464 26.1157C56.2068 25.9928 56.1238 25.8883 56.0128 25.8214Z"
			fill="white"
		/>
		<path
			d="M63.3114 7.80112H65.5145C65.5765 7.80227 65.6381 7.79025 65.6951 7.76587C65.7522 7.74148 65.8033 7.7053 65.8451 7.65974C65.8869 7.61417 65.9184 7.56027 65.9376 7.50164C65.9567 7.443 65.9631 7.38099 65.9562 7.31973V5.04112C65.966 4.97664 65.9622 4.91082 65.9448 4.84792C65.9274 4.78502 65.8969 4.72646 65.8552 4.67602C65.8136 4.62559 65.7617 4.58441 65.703 4.55516C65.6443 4.52591 65.5801 4.50924 65.5145 4.50624H63.3114C63.2458 4.50924 63.1815 4.52591 63.1228 4.55516C63.0641 4.58441 63.0123 4.62559 62.9706 4.67602C62.929 4.72646 62.8985 4.78502 62.8811 4.84792C62.8637 4.91082 62.8598 4.97664 62.8697 5.04112V7.32508C62.8636 7.38592 62.8706 7.44735 62.8901 7.50533C62.9096 7.56332 62.9413 7.61654 62.983 7.6615C63.0247 7.70647 63.0756 7.74216 63.1322 7.76622C63.1888 7.79028 63.2498 7.80218 63.3114 7.80112Z"
			fill="white"
		/>
		<path
			d="M65.8807 25.0084V10.1225L65.4768 9.64648H59.5624L59.083 10.1225V11.5988L59.5247 12.0374H63.1983V25.0084H59.3416L58.9376 25.4149V27.2977L59.3416 27.6988H69.3765L69.8506 27.2228V25.4149L69.4466 25.0084H65.8807Z"
			fill="white"
		/>
		<path
			d="M79.1745 9.38438C78.0715 9.42045 77.0067 9.79408 76.1258 10.4542L76.0881 10.4916L75.9426 10.6039L75.9049 10.069L75.5009 9.62508H74.2136L73.7342 10.1065V34.1121C73.7243 34.1766 73.7282 34.2424 73.7456 34.3053C73.763 34.3682 73.7935 34.4268 73.8351 34.4772C73.8768 34.5276 73.9286 34.5688 73.9873 34.598C74.046 34.6273 74.1103 34.644 74.1759 34.647H75.9426C76.0049 34.6391 76.065 34.619 76.1193 34.5876C76.1736 34.5563 76.221 34.5144 76.2587 34.4645C76.2964 34.4146 76.3236 34.3577 76.3387 34.2972C76.3538 34.2366 76.3565 34.1737 76.3466 34.1121V15.45C76.3466 13.3746 77.2138 12.0374 79.1422 12.0374C82.1532 12.0374 82.0455 14.653 82.0455 18.7395C82.0455 23.9386 81.3452 25.2972 78.9967 25.2972L78.5928 25.7572V27.5544L78.9967 27.9556L79.4007 27.9877C84.2162 27.9877 84.6956 22.7191 84.6956 18.777C84.701 14.6583 84.7333 9.38438 79.1745 9.38438Z"
			fill="white"
		/>
		<path
			d="M94.7952 9.38438C93.6922 9.42045 92.6274 9.79408 91.7465 10.4542L91.7088 10.4916L91.5634 10.5772L91.5257 10.0423L91.1217 9.59834H89.8397L89.3603 10.0797V34.1121C89.3504 34.1766 89.3543 34.2424 89.3717 34.3053C89.3891 34.3682 89.4196 34.4268 89.4612 34.4772C89.5029 34.5276 89.5547 34.5688 89.6134 34.598C89.6721 34.6273 89.7364 34.644 89.802 34.647H91.6064C91.6688 34.6391 91.7289 34.619 91.7831 34.5876C91.8374 34.5563 91.8849 34.5144 91.9225 34.4645C91.9602 34.4146 91.9874 34.3577 92.0025 34.2972C92.0176 34.2366 92.0203 34.1737 92.0104 34.1121V15.45C92.0104 13.3746 92.8777 12.0374 94.806 12.0374C97.817 12.0374 97.7093 14.653 97.7093 18.7395C97.7093 23.9386 97.0091 25.2972 94.6606 25.2972L94.2566 25.7572V27.5544L94.6606 27.9556L95.0646 27.9877C99.88 27.9877 100.354 22.7191 100.354 18.777C100.349 14.6583 100.386 9.38438 94.7952 9.38438Z"
			fill="white"
		/>
		<path
			d="M128.283 21.5102H126.521L126.085 21.8739C125.714 23.6444 125.239 25.0832 123.392 25.1956C120.526 25.4149 120.268 21.9863 120.268 19.8146V16.3539C120.268 14.2144 121.006 12.0428 123.247 12.0428C126.042 12.0428 125.94 14.546 125.94 17.0546V17.2365H122.929L122.525 17.6858V19.4242L122.929 19.8146H128.191L128.595 19.4081V16.3539C128.595 12.5562 127.518 9.2774 123.3 9.2774C118.005 9.2774 117.494 14.0914 117.494 18.2314C117.494 23.0453 117.898 27.9556 123.155 27.9556C126.866 27.9556 128.229 25.1528 128.665 22.0718C128.73 21.7295 128.541 21.5102 128.283 21.5102Z"
			fill="white"
		/>
		<path
			d="M107.777 7.80112H109.98C110.042 7.80227 110.103 7.79025 110.16 7.76587C110.217 7.74148 110.268 7.7053 110.31 7.65974C110.352 7.61417 110.384 7.56027 110.403 7.50164C110.422 7.443 110.428 7.38099 110.421 7.31973V5.04112C110.43 4.97676 110.426 4.91125 110.408 4.8487C110.39 4.78614 110.36 4.72791 110.318 4.67766C110.277 4.62742 110.225 4.58625 110.167 4.55674C110.109 4.52724 110.045 4.51004 109.98 4.50624H107.777C107.711 4.50924 107.647 4.52591 107.588 4.55516C107.529 4.58441 107.477 4.62559 107.436 4.67602C107.394 4.72646 107.364 4.78502 107.346 4.84792C107.329 4.91082 107.325 4.97664 107.335 5.04112V7.32508C107.329 7.38592 107.336 7.44735 107.355 7.50533C107.375 7.56332 107.406 7.61654 107.448 7.6615C107.49 7.70647 107.541 7.74216 107.597 7.76622C107.654 7.79028 107.715 7.80218 107.777 7.80112Z"
			fill="white"
		/>
		<path
			d="M110.351 25.0084V10.1225L109.947 9.64648H104.033L103.554 10.1225V11.5988L103.99 12.0374H107.669V25.0084H103.882L103.403 25.4898V27.2923L103.812 27.6988H113.842L114.321 27.2228V25.4149L113.917 25.0084H110.351Z"
			fill="white"
		/>
	</svg>
</template>
